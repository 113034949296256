import * as Sentry from '@sentry/browser'
import React, { CSSProperties, forwardRef, useEffect } from 'react'
import {
  AiOutlineFileSearch,
  AiOutlineFileText,
  AiOutlineGift
} from 'react-icons/ai'
import {
  FaArchive,
  FaArrowUp,
  FaBolt,
  FaCheck,
  FaClipboardList,
  FaCog,
  FaComments,
  FaCopy,
  FaDollarSign,
  FaEdit,
  FaExclamationCircle,
  FaHistory,
  FaHome,
  FaInbox,
  FaPhone,
  FaQuestionCircle,
  FaRegCopy,
  FaRegNewspaper,
  FaRegQuestionCircle,
  FaRegSmile,
  FaRobot,
  FaSearch,
  FaUser,
  FaUserMinus,
  FaUserPlus,
  FaUsers
} from 'react-icons/fa'
import {
  FiArrowUpRight,
  FiPhoneCall,
  FiPhoneIncoming,
  FiPhoneOff,
  FiPhoneOutgoing
} from 'react-icons/fi'
import { GoSync } from 'react-icons/go'
import { GrDrag } from 'react-icons/gr'
import {
  HiEyeOff,
  HiOutlineChevronDown,
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlineChevronUp,
  HiOutlineExternalLink,
  HiUserRemove
} from 'react-icons/hi'
import {
  HiChevronUpDown,
  HiOutlineExclamationTriangle,
  HiOutlineMagnifyingGlass,
  HiOutlineXMark
} from 'react-icons/hi2'
import { ImBubbles2, ImStarEmpty, ImStarFull } from 'react-icons/im'
import {
  IoIosFlash,
  IoIosMoon,
  IoIosPause,
  IoMdAttach,
  IoMdCloseCircle
} from 'react-icons/io'
import {
  MdAccountCircle,
  MdAdd,
  MdAddCircle,
  MdAddCircleOutline,
  MdArrowBack,
  MdArrowForward,
  MdCached,
  MdCheck,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdCheckCircle,
  MdClose,
  MdCloudDownload,
  MdCloudUpload,
  MdCompareArrows,
  MdDashboard,
  MdDataUsage,
  MdDelete,
  MdDialpad,
  MdEvent,
  MdFilterList,
  MdFormatListBulleted,
  MdGroupAdd,
  MdHelpOutline,
  MdIndeterminateCheckBox,
  MdInsertChart,
  MdInsertDriveFile,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowUp,
  MdLink,
  MdMenu,
  MdNearMe,
  MdOutlineCloudDownload,
  MdPhonelinkErase,
  MdPhonelinkRing,
  MdPlaylistAdd,
  MdRefresh,
  MdRemoveCircle,
  MdReply,
  MdRssFeed,
  MdSchedule,
  MdSend,
  MdShortText,
  MdSubdirectoryArrowRight,
  MdThumbDown,
  MdThumbUp,
  MdVerticalAlignBottom,
  MdVerticalAlignTop,
  MdVpnKey
} from 'react-icons/md'
import { PiArrowElbowDownRightBold } from 'react-icons/pi'
import { RiBracesLine } from 'react-icons/ri'
import { TbFlag } from 'react-icons/tb'
import {
  TiArrowShuffle,
  TiFlowChildren,
  TiWarningOutline
} from 'react-icons/ti'
import { VscBlank } from 'react-icons/vsc'

export const iconRef = {
  refresh: MdRefresh,
  settings: FaCog,
  search: FaSearch,
  chat: FaComments,
  money: FaDollarSign,
  edit: FaEdit,
  group: FaUsers,
  'remove-group': FaUserMinus,
  'add-members': FaUserPlus,
  home: FaHome,
  history: FaHistory,
  inbox: FaInbox,
  news: FaRegNewspaper,
  phone: FaPhone,
  'happy-face': FaRegSmile,
  user: FaUser,
  gift: AiOutlineGift,
  sync: GoSync,
  attachment: IoMdAttach,
  sleep: IoIosMoon,
  archive: FaArchive,
  activate: FaArrowUp,
  delete: MdDelete,
  download: MdCloudDownload,
  spin: MdCached,
  'avatar-circle': MdAccountCircle,
  add: MdAdd,
  'add-circle': MdAddCircle,
  'remove-circle': MdRemoveCircle,
  'add-circle-outline': MdAddCircleOutline,
  bot: FaRobot,
  'caret-up': MdKeyboardArrowUp,
  'double-caret-up': MdKeyboardDoubleArrowUp,
  'caret-down': MdKeyboardArrowDown,
  'caret-left': MdKeyboardArrowLeft,
  'double-caret-left': MdKeyboardDoubleArrowLeft,
  'caret-right': MdKeyboardArrowRight,
  'double-caret-right': MdKeyboardDoubleArrowRight,
  'arrow-left': MdArrowBack,
  'arrow-right': MdArrowForward,
  check: MdCheck,
  'check-circle': MdCheckCircle,
  close: MdClose,
  copy: FaCopy,
  regcopy: FaRegCopy,
  upload: MdCloudUpload,
  integrate: MdCompareArrows,
  data: MdDataUsage,
  dialpad: MdDialpad,
  list: MdFormatListBulleted,
  'add-group': MdGroupAdd,
  'help-circle': MdHelpOutline,
  'close-circle-outline': IoMdCloseCircle,
  chart: MdInsertChart,
  file: MdInsertDriveFile,
  location: MdNearMe,
  'cloud-download': MdCloudDownload,
  'cloud-download-outline': MdOutlineCloudDownload,
  'no-phone': MdPhonelinkErase,
  'phone-ring': MdPhonelinkRing,
  'list-add': MdPlaylistAdd,
  reply: MdReply,
  pause: IoIosPause,
  feed: MdRssFeed,
  clock: MdSchedule,
  send: MdSend,
  'speaker-phone': FiPhoneCall,
  'thumb-down': MdThumbDown,
  'thumb-up': MdThumbUp,
  'sub-dir': MdSubdirectoryArrowRight,
  export: MdVerticalAlignBottom,
  import: MdVerticalAlignTop,
  key: MdVpnKey,
  'arrow-down-right': PiArrowElbowDownRightBold,
  'simple-text': MdShortText,
  shuffle: TiArrowShuffle,
  warning: TiWarningOutline,
  link: MdLink,
  learn: AiOutlineFileSearch,
  undelivered: FiPhoneOff,
  filter: MdFilterList,
  'messages-sent': FiPhoneOutgoing,
  'messages-unconfirmed': FiPhoneCall,
  'messages-confirmed': FiPhoneIncoming,
  exclamation: FaExclamationCircle,
  'exclaimation-triangle': HiOutlineExclamationTriangle,
  'external-link': HiOutlineExternalLink,
  dashboard: MdDashboard,
  draft: AiOutlineFileText,
  flow: TiFlowChildren,
  keyword: FiArrowUpRight,
  automation: IoIosFlash,
  grab: GrDrag,
  menu: MdMenu,
  code: RiBracesLine,
  form: FaClipboardList,
  event: MdEvent,
  'canned-response': FaBolt,
  'simple-flag': TbFlag,
  'question-circle': FaQuestionCircle,
  'question-circle-outline': FaRegQuestionCircle,
  'chevron-left': HiOutlineChevronLeft,
  'chevron-right': HiOutlineChevronRight,
  'chevron-down': HiOutlineChevronDown,
  'chevron-up': HiOutlineChevronUp,
  'chevron-up-down': HiChevronUpDown,
  unstarred: ImStarEmpty,
  starred: ImStarFull,
  'speech-bubbles': ImBubbles2,
  'checkbox-checked': MdCheckBox,
  checkbox: MdCheckBoxOutlineBlank,
  'checkbox-indetermined': MdIndeterminateCheckBox,
  'magnifying-glass': HiOutlineMagnifyingGlass,
  'x-mark': HiOutlineXMark,
  blank: VscBlank,
  'fa-check': FaCheck,
  'eye-off': HiEyeOff,
  'user-remove': HiUserRemove
}

export const iconTypes = Object.keys(iconRef)

export interface IconProps {
  type: keyof typeof iconRef
  clickable?: boolean
  size?: number
  top?: number
  display?: 'inherit' | 'inline-block' | 'flex'
  className?: string
  onClick?: (e?: any) => void
  primary?: boolean
  disabled?: boolean
  stopPropagation?: boolean
  title?: string
}

const Icon = forwardRef<IconProps, any>((props, ref) => {
  const {
    type,
    onClick = () => {},
    stopPropagation = false,
    clickable,
    disabled = false,
    display = 'inline-block',
    top,
    size,
    minSize,
    primary,
    className,
    strokeColor,
    fillColor,
    id,
    title
  } = props

  const IconComponent = iconRef[type]

  useEffect(() => {
    if (!iconRef[type]) {
      // For whatever reason they removed the enum.
      Sentry.captureMessage(`Unknown Icon: ${type}`, 'error')
    }
  }, [type])

  if (!iconRef[type]) return <span></span>

  const style: CSSProperties = {
    display,
    top: top != null ? top : -1
  }
  if (size != null) {
    style.fontSize = size
  }
  if (minSize != null) {
    style.minWidth = minSize
    style.minHeight = minSize
  }
  if (clickable) {
    style.cursor = 'pointer'
  }
  if (primary) {
    style.color = '#3d4575'
  }
  if (disabled) {
    style.color = '#D8D8E6'
  }
  if (strokeColor) {
    style.stroke = strokeColor
  }
  if (fillColor) {
    style.fill = fillColor
  }

  return (
    <IconComponent
      title={title}
      ref={ref}
      style={style}
      className={className}
      id={id}
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation()
        }
        if (onClick) onClick()
      }}
    />
  )
})

export default Icon
