/**
 * ButtonBase is a shared component that has buttons variants defined by the Style Guide.
 */
import classNames from 'classnames'
import { useRouter } from 'next/router'

import {
  buttonClassNamePrimaryBase,
  buttonClassNamePrimaryCtaBase,
  buttonClassNamePrimaryCtaSm,
  buttonClassNamePrimarySm,
  buttonClassNameSecondaryBase,
  buttonClassNameSecondaryCtaBase,
  buttonClassNameSecondaryCtaSm,
  buttonClassNameSecondaryRemovalActionBase,
  buttonClassNameSecondaryRemovalActionBaseSm,
  buttonClassNameSecondarySm,
  buttonClassNameTertiaryCtaSm,
  buttonClassNameTertiarySm
} from '../../../lib/util/fe/style-guide'

export type ButtonBaseProps = {
  ariaDisabled?: boolean
  children: any
  className?: string
  cta?: boolean
  disabled?: boolean
  full?: boolean
  id?: string
  onClick?: (e?: any) => void
  route?: string
  small?: boolean
  style?: any
  testId?: string
  type?: 'button' | 'reset' | 'submit'
  variant?: 'primary' | 'secondary' | 'tertiary' | 'removeAction'
}

const ButtonBase = ({
  ariaDisabled,
  children,
  className,
  cta,
  disabled,
  full,
  id,
  onClick,
  route,
  small,
  style,
  testId = '',
  type,
  variant,
  ...props
}: ButtonBaseProps) => {
  const router = useRouter()

  let variantClassName = small
    ? cta
      ? buttonClassNamePrimaryCtaSm
      : buttonClassNamePrimarySm
    : cta
    ? buttonClassNamePrimaryCtaBase
    : buttonClassNamePrimaryBase

  if (variant === 'secondary') {
    variantClassName = small
      ? cta
        ? buttonClassNameSecondaryCtaSm
        : buttonClassNameSecondarySm
      : cta
      ? buttonClassNameSecondaryCtaBase
      : buttonClassNameSecondaryBase
  } else if (variant === 'tertiary') {
    variantClassName = cta
      ? buttonClassNameTertiaryCtaSm
      : buttonClassNameTertiarySm
  } else if (variant === 'removeAction') {
    variantClassName = small
      ? buttonClassNameSecondaryRemovalActionBaseSm
      : buttonClassNameSecondaryRemovalActionBase
  }

  const classes = classNames(
    variantClassName,
    full ? 'w-full max-w-full' : undefined,
    className ?? undefined
  )

  return (
    <button
      {...props}
      aria-disabled={ariaDisabled ? true : undefined}
      className={classes}
      data-testid={`${testId}Button`}
      disabled={disabled ? true : undefined}
      id={id ?? undefined}
      onClick={(e) => (route ? router.push(route) : onClick?.(e))}
      style={style}
      type={type}
    >
      {children}
    </button>
  )
}

export default ButtonBase
