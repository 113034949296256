import * as Sentry from '@sentry/browser'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import React, { useContext, useEffect, useState } from 'react'

import UserMenu from '~/components/shared/template/TemplateHeader/UserMenu'
import Icon from '~/components/shared/ui/Icon'
import { initializeStores } from '~/hooks/stores/initializeStores'
import { classNames } from '~/lib/util/fe/classNames'
import { AuthContext } from '~/lib/util/fe/dataContext/authenticationContext'

import ButtonBase from '../../ui/ButtonBase'

const UserMenuDynamic = dynamic(() => Promise.resolve(UserMenu), { ssr: false })

NProgress.configure({
  template:
    "<div class='top-[60px] h-[2px] fixed w-full bg-primary' role='bar'></div>"
})

// Example of an AlertBanner
/*
import CallNumberAlert from '~/components/shared/template/Header/CallNumberAlert'
const CallNumberAlertBanner = dynamic(() => Promise.resolve(CallNumberAlert), {
  ssr: false
})
*/

type TemplateHeaderProps = {
  activePage?: string
  hideMenuButton?: boolean
}
const TemplateHeader = ({
  activePage,
  hideMenuButton = false
}: TemplateHeaderProps) => {
  const {
    authorizedValues: { user: currentUser },
    showNavMenu,
    updateShowNavMenu
  } = useContext(AuthContext)

  const [firstName, setFirstName] = useState(currentUser?.firstName || 'User')
  const [isAdmin, setIsAdmin] = useState(currentUser?.isAdmin || false)

  useEffect(() => {
    setFirstName(currentUser?.firstName || 'User')
    setIsAdmin(currentUser?.isAdmin || false)
  }, [currentUser])

  Sentry.configureScope(function (scope) {
    if (currentUser) {
      scope.setUser({
        id: `${currentUser.id}`,
        email: currentUser.email,
        organizationId: currentUser.organizationId
      })
    }
  })

  const [initialLoad, setInitialLoad] = useState(true)
  const onInitialLoad = async () => {}
  useEffect(() => {
    if (initialLoad) {
      onInitialLoad()
      setInitialLoad(false)
    }
  }, [])

  const router = useRouter()

  useEffect(() => {
    const progressStart = (url) => {
      if (currentUser) {
        NProgress.start()
      }
    }
    const progressComplete = () => {
      NProgress.done()
    }

    router.events.on('routeChangeStart', progressStart)
    router.events.on('routeChangeComplete', progressComplete)
    router.events.on('routeChangeError', progressComplete)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      NProgress.done()
      router.events.off('routeChangeStart', progressStart)
      router.events.off('routeChangeComplete', progressComplete)
      router.events.off('routeChangeError', progressComplete)
    }
  }, [router])

  const handleLogout = async () => {
    return fetch('/api/sign-out', { credentials: 'include' }).then(() => {
      // re-initialize the stores
      initializeStores()
      return router.push({
        pathname: '/sign-in'
      })
    })
  }

  return (
    <>
      {currentUser && (
        <div
          className="bg-white fixed top-0 left-0 right-0 z-40 h-[60px] shadow"
          data-testid="header"
        >
          <div className="w-full max-w-full mx-auto bg-white flex justify-between">
            <div className="flex items-center">
              {!hideMenuButton && (
                <div className="inline-block px-2 cursor-pointer w-[75px]">
                  <ButtonBase
                    variant="tertiary"
                    onClick={() => updateShowNavMenu(!showNavMenu)}
                    testId="sideNavMobileMenu"
                  >
                    <Icon type="menu" size={30} />
                  </ButtonBase>
                </div>
              )}
              <div
                className={classNames(
                  'h-[60px]',
                  hideMenuButton ? 'pl-2' : undefined
                )}
              >
                <Link
                  className="inline-block h-full"
                  href="/"
                  data-testid="headerOrgLogo"
                >
                  <>
                    <div
                      className="h-[60px] w-[52px] sm:w-[166px] bg-[length:32px_30px] sm:bg-[length:144px_23px] px-5 float-left cursor-pointer 
            bg-no-repeat bg-center  bg-[url('https://striveimages.s3.us-east-2.amazonaws.com/striveMark.png')] sm:bg-[url('https://striveimages.s3.us-east-2.amazonaws.com/strive-logo-main.png')]"
                    />
                  </>
                </Link>
              </div>
            </div>
            <div className="h-[60px] inline-flex items-center">
              <div className=" text-sm px-0.5 md:px-5">
                <a
                  href="https://www.strivemessaging.org/resources"
                  target="__blank"
                  className="text-primary hover:text-primary-hover"
                  data-testid="headerResourcesLink"
                >
                  <Icon className="text-xl inline-block" type="help-circle" />
                  <span className="sr-only">Strive Resources</span>
                </a>
              </div>
              <div className="">
                <UserMenuDynamic
                  firstName={firstName}
                  isAdmin={isAdmin}
                  onLogout={handleLogout}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TemplateHeader
