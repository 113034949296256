/**
 * Button classes defined by the Style Guide.
 */
/**
 * Going to have all buttons share a single disabled style
 */
const disabled =
  ' disabled:bg-primary-disabled disabled:border-primary-disabled disabled:cursor-not-allowed disabled:text-white'

const buttonSharedClassName =
  ' max-w-fit shadow-none border rounded' +
  ' focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-success'
const buttonBaseClassName = ' px-4 py-2'
const buttonSmClassName = ' px-2 py-1 text-sm min-h-min min-w-min'

// PRIMARY
const buttonClassNamePrimary =
  ' bg-primary text-white border-primary' +
  ' active:bg-primary-600 active:text-white active:border-primary-600' +
  ' hover:bg-primary-hover hover:text-white hover:border-white' +
  disabled

export const buttonClassNamePrimaryBase =
  buttonSharedClassName +
  buttonClassNamePrimary +
  ' text-base font-semibold' +
  buttonBaseClassName

export const buttonClassNamePrimarySm =
  buttonSharedClassName +
  buttonClassNamePrimary +
  ' font-semibold' +
  buttonSmClassName

// PRIMARY CTA
const buttonClassNamePrimaryCta =
  ' text-base font-semibold' +
  ' bg-active text-white border-active' +
  ' active:bg-primary-600 active:text-white active:border-primary-600' +
  ' hover:bg-primary-600 hover:text-white hover:border-primary-600' +
  disabled

export const buttonClassNamePrimaryCtaBase =
  buttonSharedClassName +
  buttonClassNamePrimaryCta +
  ' text-base font-semibold' +
  buttonBaseClassName

export const buttonClassNamePrimaryCtaSm =
  buttonSharedClassName +
  buttonClassNamePrimaryCta +
  ' font-semibold' +
  buttonSmClassName

// SECONDARY
const buttonClassNameSecondary =
  ' bg-white text-primary border border-primary' +
  ' active:bg-primary-600 active:text-white' +
  ' hover:bg-primary-hover hover:text-white hover:border-primary' +
  disabled

const buttonClassNameSecondaryRemovalAction =
  ' bg-white text-error border border-error' +
  ' active:bg-primary-600 active:text-error' +
  ' hover:bg-primary-hover hover:text-error hover:border-primary' +
  disabled
export const buttonClassNameSecondaryBase =
  buttonSharedClassName +
  buttonClassNameSecondary +
  ' text-base font-semibold' +
  buttonBaseClassName

export const buttonClassNameSecondarySm =
  buttonSharedClassName +
  buttonClassNameSecondary +
  ' font-semibold' +
  buttonSmClassName

export const buttonClassNameSecondaryRemovalActionBase =
  buttonSharedClassName +
  buttonClassNameSecondaryRemovalAction +
  ' font-semibold' +
  buttonBaseClassName
export const buttonClassNameSecondaryRemovalActionBaseSm =
  buttonSharedClassName +
  buttonClassNameSecondaryRemovalAction +
  ' font-semibold' +
  buttonSmClassName

// SECONDARY CTA
const buttonClassNameSecondaryCta =
  ' bg-white text-active border border-active' +
  ' active:bg-primary-600 active:text-white' +
  ' hover:bg-primary-hover hover:text-white' +
  disabled

export const buttonClassNameSecondaryCtaBase =
  buttonSharedClassName +
  buttonClassNameSecondaryCta +
  ' text-base font-semibold' +
  buttonBaseClassName

export const buttonClassNameSecondaryCtaSm =
  buttonSharedClassName +
  buttonClassNameSecondaryCta +
  ' font-semibold' +
  buttonSmClassName

// TERTIARY
const buttonClassNameTertiary =
  buttonSharedClassName +
  buttonSmClassName +
  ' text-sm' +
  ' bg-transparent text-primary border-transparent' +
  ' active:bg-transparent active:border-transparent active:text-primary-600' +
  ' hover:bg-transparent hover:border-transparent hover:text-primary-600' +
  ' focus:text-active' +
  disabled

const buttonClassNameTertiaryCta =
  buttonSharedClassName +
  buttonSmClassName +
  ' text-sm' +
  ' bg-transparent text-active border-transparent' +
  ' active:bg-transparent active:border-transparent active:text-primary-600' +
  ' hover:bg-transparent hover:border-transparent hover:text-primary-600' +
  ' focus:text-active' +
  disabled

export const buttonClassNameTertiarySm =
  buttonSharedClassName + buttonClassNameTertiary + buttonSmClassName

export const buttonClassNameTertiaryCtaSm =
  buttonSharedClassName + buttonClassNameTertiaryCta + buttonSmClassName

/**
 * Input classes defined by the Style Guide.
 */
export const inputTextBaseClasses =
  'shadow-none text-primary-600 focus:ring-active focus:border-active block sm:text-sm border-primary-600 rounded w-full'

/**
 * Legacy table className
 */
export const legacyTableClasses =
  'bg-white my-5 mx-auto w-full border-collapse border border-primary-300 border-spacing-2.5 rounded text-sm shadow' +
  ' [&_thead]:font-bold [&_thead]:text-left [&_thead]:font-bold [&_thead]:bg-bg [&_thead]:rounded-b' +
  ' [&_thead_tr]:border-t-0' +
  ' [&_th]:p-2.5 [&_td]:p-2.5 [&_th]:transition-all [&_td]:transition-all' +
  ' [&_tr]:transition-all [&_tr]:border-t [&_tr]:border-t-primary-300'

/**
 * Dropdown Menu classes
 */
export const menuItemsClasses =
  'absolute right-0 z-20 mt-2 min-w-[160px] w-fit max-w-xs origin-top-right divide-y divide-primary-300 rounded-md bg-white shadow-lg ring-2 ring-primary ring-opacity-5 focus:outline-none'

export const menuItemLinkClasses =
  'block px-4 py-2 text-left hover:text-primary hover:bg-primary-300 active:bg-primary-300 text-primary cursor-pointer'
export const menuItemDivClasses =
  'px-4 py-2 text-left hover:bg-primary-300 active:bg-primary-300 text-primary'
