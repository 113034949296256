import Head from 'next/head'
import * as React from 'react'

const Layout: React.FC<any> = (props) => {
  return (
    <div>
      <Head>
        <title>{props.title}</title>
      </Head>

      <div>{props.children}</div>

      <footer className="fixed p-2.5 bottom-0 w-full" data-testid="footer">
        <p className="footerBlock" data-testid="footerCopyrightText">
          © {new Date().getFullYear()} Strive Messaging Inc.
        </p>
        <p className="footerBlock">
          <a
            href="https://www.strivemessaging.org/terms/"
            target="__blank"
            data-testid="footerTosLink"
          >
            Terms
          </a>
        </p>
        <p className="footerBlock">
          <a
            href="https://www.strivemessaging.org/privacy/"
            target="__blank"
            data-testid="footerPrivacyPolicyLink"
          >
            Privacy
          </a>
        </p>
        <p className="footerBlock">
          Powered by{' '}
          <a
            href="https://www.strivemessaging.org/"
            target="__blank"
            data-testid="footerPoweredByLink"
          >
            Strive Messaging
          </a>
        </p>
        <p className="footerBlock">
          Need help?{' '}
          <a
            href="mailto:help@strivemessaging.org"
            data-testid="footerContactUsLink"
          >
            Contact us
          </a>
        </p>
      </footer>
    </div>
  )
}
export default Layout
