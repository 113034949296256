import { classNames } from '@lib/util/fe/classNames'
import { ReactNode } from 'react'

export type PageFullContainerProps = {
  children?: ReactNode
  className?: string
}

const PageFullContainer = ({
  children,
  className,
  ...props
}: PageFullContainerProps) => {
  const classes = classNames(
    'w-screen flex my-0 mx-auto flex-col fixed top-[60px] bottom-[40px] overflow-y-auto py-6 px-4 xl:px-[calc((100vw-1340px)/2+16px)]',
    className || undefined,
    'mainPageWrapper'
  )

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  )
}

export default PageFullContainer
