/* This example requires Tailwind CSS v2.0+ */
import { Menu, Transition } from '@headlessui/react'
import Link from 'next/link'
import { Fragment } from 'react'

import {
  menuItemLinkClasses,
  menuItemsClasses
} from '~/lib/util/fe/style-guide'

import Icon from '../../ui/Icon'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
interface UserMenuProps {
  firstName: string
  isAdmin: boolean
  onLogout: () => any
}

const UserMenu = ({ firstName, isAdmin, onLogout }: UserMenuProps) => {
  const handleLogout = (e) => {
    e.preventDefault
    onLogout()
    return false
  }

  return (
    <Menu
      as="div"
      className="relative inline-block text-left float-right h-[60px]"
      data-testid="headerMenu"
    >
      <div className="h-[60px] items-center">
        <Menu.Button
          as="div"
          className="h-full inline-flex justify-center items-center w-full cursor-pointer border-0 px-4 py-0 bg-white text-sm font-medium text-primary hover:bg-bg"
          data-testid="headerMenuAccountName"
        >
          <span className="inline-block px-2 h-full leading-[60px]">
            <Icon
              type="avatar-circle"
              className="text-xl align-middle opacity-40"
            />
          </span>
          <span className="inline-block px-2 pt-[2px] h-full leading-[60px]">
            {firstName}
          </span>
          <span className="mt-[6px] inline-block w-0 h-0 ml-1 border-solid border-4 border-x-transparent border-b-0"></span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={menuItemsClasses}>
          {isAdmin && (
            <Menu.Item data-testid="headerMenuManageUsersLink">
              {({ active }) => (
                <Link
                  href="/manage-users"
                  className={classNames(
                    menuItemLinkClasses,
                    active ? 'bg-primary-300' : undefined
                  )}
                >
                  Manage Users
                </Link>
              )}
            </Menu.Item>
          )}
          {isAdmin && (
            <>
              <Menu.Item data-testid="headerMenuBillingLink">
                {({ active }) => (
                  <Link
                    href="/billing"
                    className={classNames(
                      menuItemLinkClasses,
                      active ? 'bg-primary-300' : undefined,
                      'whitespace-nowrap'
                    )}
                  >
                    Billing
                  </Link>
                )}
              </Menu.Item>
            </>
          )}
          <Menu.Item data-testid="headerMenuLogOutLink">
            {({ active }) => (
              <Link
                href="/sign-in"
                onClick={handleLogout}
                className={classNames(
                  menuItemLinkClasses,
                  active ? 'bg-primary-300' : undefined
                )}
              >
                Log Out
              </Link>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default UserMenu
