import { AuthContext } from '@lib/util/fe/dataContext/authenticationContext'
import { ReactNode, useContext, useMemo, useState } from 'react'

type AuthorizedAccessProps = {
  admin?: boolean
  children: ReactNode
  creator?: boolean
  billingRead?: boolean
  billingWrite?: boolean
  requireCampaign?: boolean
  requireUser?: boolean
  checkPermission?: boolean
}

const AuthorizedAccess = ({
  admin = false,
  children,
  creator = false,
  billingRead = false,
  billingWrite = false,
  requireCampaign = true,
  requireUser = true,
  checkPermission = true
}: AuthorizedAccessProps) => {
  const {
    authorizedValues: { campaign: currentCampaign, user: currentUser }
  } = useContext(AuthContext)
  const [showChildren, setShowChildren] = useState(false)
  useMemo(() => {
    // logged in user
    if (requireUser && !currentUser) {
      setShowChildren(false)
      return
    }
    // selected campaign
    if (requireCampaign && !currentCampaign) {
      setShowChildren(false)
      return
    }
    // billing read permissions
    if (
      billingRead &&
      !(
        currentUser?.isCreator ||
        ['read', 'write'].includes(currentUser?.billingPermissions)
      ) &&
      !currentUser?.isAdmin
    ) {
      setShowChildren(false)
      return
    }
    // billing write permissions
    if (
      billingWrite &&
      !(
        currentUser?.isCreator ||
        ['write'].includes(currentUser?.billingPermissions)
      ) &&
      !currentUser?.isAdmin
    ) {
      setShowChildren(false)
    }
    // standard admin
    if (admin && !currentUser?.isAdmin) {
      setShowChildren(false)
      return
    }
    // account creator
    if (creator && !currentUser?.isCreator) {
      setShowChildren(false)
      return
    }
    //check specific permissions
    if (!checkPermission && !currentUser.isAdmin) {
      setShowChildren(false)
      return
    }
    setShowChildren(true)
  }, [
    currentCampaign,
    currentUser,
    requireCampaign,
    requireUser,
    admin,
    creator,
    billingRead,
    billingWrite
  ])

  return <>{showChildren && <>{children}</>}</>
}

export default AuthorizedAccess
